
.titleForm{
	-webkit-animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}


.formInput 
{
	-webkit-animation: scale-in-tr 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-tr 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes scale-in-tr {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-tr {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

.table{
        height: calc(100vh - 126px);
        
        overflow: auto;

        
}

.table::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  
}

.table::-webkit-scrollbar-thumb {
  background-color: #1E3A8A;
  border-radius: 10px;
}

.table::-webkit-scrollbar-thumb:hover {
  background:radial-gradient(#ffffff,rgb(0, 13, 255))
}

/* Firefox */
.table::-moz-scrollbar {
  width: 10px;
  height: 10px;
}

.table::-moz-scrollbar-thumb {
   background-color: #1E3A8A;
}

.table::-moz-scrollbar-thumb:hover {
  background:radial-gradient(#ffffff,rgb(0, 13, 255))
}
